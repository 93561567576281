import React, { Fragment, useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link as ScrollLink } from "react-scroll"
import MobileMenu from "./mobile-menu"
import { MenuContext } from "../context/menu-context"
import "../styles/custom.css"
import "../styles/style.css"
import "../styles/hover.css"
import "../styles/animate.css"
import "../styles/fontawesome.css"
import "font-awesome/css/font-awesome.min.css"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./carouselStyle.css"

const Layout = ({ PageTitle, children }) => {
  const [scrollTop, setScrollTop] = useState(false)
  const { menuStatus } = useContext(MenuContext)
  const handleScrollTop = () => {
    if (window.scrollY > 70) {
      setScrollTop(true)
    } else if (window.scrollY < 70) {
      setScrollTop(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop)
    return () => {
      window.removeEventListener("scroll", handleScrollTop)
    }
  }, [scrollTop])

  return (
    <Fragment>
      <Helmet>
        <title>{PageTitle} - MMGP</title>
      </Helmet>
      <div id="wrapper">{children}</div>

      {true === menuStatus ? <MobileMenu /> : null}
      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top scroll-to-target"
        >
          <i className="fa fa-angle-up"></i>
        </ScrollLink>
      ) : null}
    </Fragment>
  )
}

export default Layout
