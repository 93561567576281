import logoDark from "../images/finalgo.png"
import aboutThree1 from "../images/printing1.jpg"
import projectImage1 from "../images/project2.png"
import projectImage2 from "../images/project1.jpg"
import projectImage3 from "../images/project3.png"
import errorImage from "../images/404.png"
import progressImage1 from "../images/what-we-do-2-11.jpg"
import sliderOne1 from "../images/slider1-11.jpg"
import aboutTwo1 from "../images/thinkit.jpg"
import clientImage1 from "../images/client1.png"
import clientImage2 from "../images/client2.png"
import clientImage3 from "../images/client3.jpg"
import clientImage4 from "../images/client-41.jpg"
import clientImage5 from "../images/client5.png"
import clientImage6 from "../images/client6.png"
import clientImage7 from "../images/client7.png"
import clientImage8 from "../images/client8.png"

export const Logo = {
  dark: logoDark,
}

export const NavLinksData = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/",
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2",
    //   },
    // ],
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/services",
  },
  {
    name: "Pricing",
    url: "/pricing",
  },
  {
    name: "Contact",
    url: "/contact",
  },
]

export const ContactFromData = {
  sectionContent: {
    title: "Send Message",
    subText: "Contact with us",
  },
}

export const SliderOneData = [
  {
    image: sliderOne1,
    // title: "One Stop \n Printing Solution",
    // subText: "We bring design together with technology",
    button: {
      label: "Our Pricing",
      url: "/pricing",
    },
  },
  // {
  //   image: sliderOne2,
  //   title: "One Stop \n Printing Solution",
  //   subText: "We bring design together with technology",
  //   button: {
  //     label: "Our Pricing",
  //     url: "/pricing",
  //   },
  // },
]

export const SliderTwoData = {
  image: "",
  posts: [
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing",
      },
    },
    {
      title: "Quality \n Printing \n Service",
      subTitle: "We believe in the power of great design",
      button: {
        label: "Our Pricing",
        url: "/pricing",
      },
    },
  ],
}

export const CallToActionData = {
  sectionContent: {
    subText: "Business stationery printing",
    title: "Even More Good \n Stuff",
    textAlignment: "left",
  },
  lists: [
    "Aesthetic Design",
    "Quality Work",
    "Services on Deadline",
    "Good User Experience",
  ],
  button: {
    label: "Learn More",
    url: "/services",
  },
}

export const AboutThreeData = {
  backgroundImage: aboutThree1,
  sectionContent: {
    subText: "Checkout our services",
    title: "Explore Printing \n Services",
    textAlignment: "left",
  },
  content:
    "We’re an online print and design company that is passionate about great design and the difference it can make to our customers and the world. \n \n We want to set a new standard for print, with remarkable new products that bring great design and uncompromising, high standards to the web.",
  button: {
    label: "Learn More",
    url: "/services",
  },
  phone: "+977-01-4988074",
  posts: [
    {
      title: "T-Shirt \nPrinting",
      icon: "printify-icon-t-shirt-with-square",
      url: "/services",
    },
    {
      title: "Flyer \nPrinting",
      icon: "printify-icon-brochure-folded",
      url: "/services",
    },
    {
      title: "Poster \n Printing",
      icon: "printify-icon-continuous-feed-paper",
      url: "/services",
    },
    {
      title: "Sticker  \n Printing",
      icon: "printify-icon-circular-sticker",
      url: "/services",
    },
  ],
}

export const AboutTwoData = {
  sectionContent: {
    subText: "Welcome to manjushree",
    title: "Think it. Print it.",
    textAlignment: "left",
  },
  content:
    "When we say we’re not happy until you’re happy, we really do mean it. So whether it’s a typo or an image that didn’t print quite the way you hoped, we’ll do everything we can to \n fix it. \n \n We love great design and believe it can work wonders for every business. That’s why we make it simple to create beautiful, expertly crafted business stationery.",
  url: "/about",
  image: aboutTwo1,
}

export const ServiceTabOneData = [
  {
    title: "T-Shirt\n Printing",
    icon: "printify-icon-t-shirt-with-square",
    content: {
      title: "T-Shirt Printing",
      subText: "Our services",
      content:
        "Choose the perfect flyer design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \nOriginal single-sided flyers (writeable on one side) that you can send without an envelope, Original double-sided \nflyers (more impactful) and luxe flyers (extra thick and luxurious).",
    },
  },
  {
    title: "Flyer \n Printing",
    icon: "printify-icon-brochure-folded",
    content: {
      title: "Flyer Printing",
      subText: "Our services",
      content:
        "Choose the perfect flyer design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided flyers (writeable on one side) that you can send without an envelope, Original double-sided \n flyers (more impactful) and luxe flyers (extra thick and luxurious).",
    },
  },
  {
    title: "Poster \n Printing",
    icon: "printify-icon-continuous-feed-paper",
    content: {
      title: "Poster Printing",
      subText: "Our services",
      content:
        "Choose the perfect flyer design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided flyers (writeable on one side) that you can send without an envelope, Original double-sided \n flyers (more impactful) and luxe flyers (extra thick and luxurious).",
    },
  },
  {
    title: "Sticker\n Printing",
    icon: "printify-icon-circular-sticker",
    content: {
      title: "Sticker \n  Printing",
      subText: "Our services",
      content:
        "Choose the perfect flyer design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided flyers (writeable on one side) that you can send without an envelope, Original double-sided \n flyers (more impactful) and luxe flyers (extra thick and luxurious).",
    },
  },
  {
    title: "Postcard \n Printing",
    icon: "printify-icon-white-sheets",
    content: {
      title: "Postcard Printing",
      subText: "Our services",
      content:
        "Choose the perfect flyer design, You can’t go wrong. We start at premium and go all the way to extra fancy. There’s \n Original single-sided flyers (writeable on one side) that you can send without an envelope, Original double-sided \n flyers (more impactful) and luxe flyers (extra thick and luxurious).",
    },
  },
]

export const ProjectOneData = [
  {
    image: projectImage1,
    category: "printing",
    title: "Wide range of Printing Services",
    url: "/services",
  },
  {
    image: projectImage2,
    category: "printing",
    title: "With No Compromise on Quality",
    url: "/services",
  },
  {
    image: projectImage3,
    category: "printing",
    title: "Best Price and Quick Service",
    url: "/services",
  },
  // {
  //   image: projectImage4,
  //   category: "printing",
  //   title: "Square Paper Book",
  //   url: "/services",
  // },
  // {
  //   image: projectImage5,
  //   category: "printing",
  //   title: "Book Copy",
  //   url: "/project-details",
  // },
  // {
  //   image: projectImage6,
  //   category: "printing",
  //   title: "C Creative Mess",
  //   url: "/project-details",
  // },
]
export const errorData = {
  title: "Oops! This page is not available",
  text: "Please go back to home and try to find out once again.",
  image: errorImage,
}

export const ProgressData = {
  sectionImage: progressImage1,
  sectionContent: {
    title: "You’ll get a \n perfect fit for \n your business.",
    subText: "What we do",
    content:
      "Handing out a business card is often the first impression people take of your business, so you need to get it right. Using your own photography or artwork to create custom business cards can help, and PRINTIFY makes the process easy with our simple online tools and templates.",
  },
  posts: [
    {
      title: "Flyer \n Printing",
      price: "9.99",
      percentCount: "90",
    },
    {
      title: "Sticker \n Printing",
      price: "299.99",
      percentCount: "70",
    },
  ],
}

export const PricingData = {
  sectionContent: {
    title: "Pricing",
    subText: "Choose best price",
    content:
      "For Manjushree, print is not just a piece of paper but a powerful business tool. We take the best care of your print job beginning from placing an order to design review, print quality. Price varies on Quanity though.",
  },
  posts: [
    {
      name: "for Business Card",
      price: "1.00",
      icon: "printify-icon-continuous-feed-paper",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored & Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
    {
      name: "for Brochure",
      price: "35.00",
      icon: "printify-icon-brochure-folded",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored &  Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
    {
      name: "for LetterHead",
      price: "45.00",
      icon: "printify-icon-white-sheets",
      url: "/contact",
      lists: [
        {
          name: "Multi-Colored &  Single-Colored",
        },
        {
          name: "Standard Or Premium Paper",
        },
        {
          name: "Extra Thick",
        },
      ],
    },
  ],
}

export const ClientCarouselData = [
  {
    image: clientImage1,
  },
  {
    image: clientImage2,
  },
  {
    image: clientImage3,
  },
  {
    image: clientImage4,
  },
  {
    image: clientImage5,
  },
  {
    image: clientImage6,
  },
  {
    image: clientImage7,
  },
  {
    image: clientImage8,
  },
]

export const ContactInfosData = {
  sectionContent: {
    title: "Details",
    subText: "Contact info",
    textAlignment: "center",
  },
  posts: [
    {
      title: "Address",
      text: "Khusibu Chowk, Nayabazaar, Kathmandu, Nepal",
    },
    {
      title: "Phone",
      text: "Local: +977-01-4988074  \n  Mobile: +977-9851027130",
    },
    {
      title: "Email",
      text: "manjushress.press@gmail.com",
    },

    {
      title: "Follow",
      socials: [
        {
          icon: "fa fa-facebook-f",
          url: "#",
        },
        {
          icon: "fa fa-linkedin-in",
          url: "#",
        },
        {
          icon: "fa fa-instagram",
          url: "#",
        },
      ],
    },
  ],
}

export const FooterAboutData = {
  title: "About Printify",
  text: "Manjushreee Multi-Graphic Press Pvt. Ltd. first opened its doors to customers in 2065 B.S., situated in Nayabazaar, Kathmandu, Nepal. Customer Service and affordable printing has been our focus for over 12 years.",
}

export const FooterLinksData = {
  title: "Explore",
  links: [
    {
      label: "About",
      url: "/about",
    },
    {
      label: "Contact",
      url: "/contact",
    },
    {
      label: "Our Services",
      url: "/services",
    },
    {
      label: "Plans & Pricing",
      url: "/pricing",
    },
  ],
}

export const FooterContactData = {
  title: "Contact",
  infos: [
    {
      text: "+977-9851027130",
      url: "tel:+977-9851027130",
    },
    {
      text: "manjushress.press@gmail.com",
      url: "mailto:manjushress.press@gmail.com",
    },
    {
      text: "Khusibu Chowk, Nayabazaar, \n Kathmandu, Nepal",
      url: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3531.994205534402!2d85.30044706506227!3d27.717465182787695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196c1b0f83db%3A0xaaeb46d683a07003!2sManjushree%20Multi%20Graphic%20Press%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1645866436097!5m2!1sen!2snp",
    },
  ],
}

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-facebook-f",
      url: "https://www.facebook.com/mmgraphicpress",
    },
    {
      icon: "fa fa-linkedin-in",
      url: "https://www.linkedin.com/company/manjushree-multi-graphic-press/",
    },
    {
      icon: "fa fa-instagram",
      url: "https://www.instagram.com/manjushreepress/",
    },
  ],
}
